






















































































































import store from '@/store/store'
import ManageProductModal from './ManageProduct.modal.vue'
import ManageGvdModal from './ManageGvd.modal.vue'
import ManageSlideDeckModal from './ManageSlideDeck.modal.vue'
import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import { Route } from 'vue-router'
import { Gvd, GvdSection, GvdPage } from '@/store/modules/gvd'
import { SlideDeck, SlideDeckPage, SlideDeckSection } from '@/store/modules/slideDeck'
import { EventBus } from '@/utils/event-bus'

@Component({
  components: {
    ManageProductModal,
    ManageGvdModal,
    ManageSlideDeckModal
  }
})
export default class ProductAdminDashboard extends Vue {
  // props
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage product',
      to: { name: 'manage-product' }
    }
  ]

  activeGvd: Gvd | null = null
  activeSlideDeck: SlideDeck | null = null
  // getters
  get product () {
    return this.$store.getters.getActiveProduct
  }

  hasPermissions = hasPermissions

  async beforeRouteUpdate (to: Route, from: Route, next: Function) {
    await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    await store.dispatch('loadGvdsByProductKey', to.params.productKey)
    await store.dispatch('loadSlideDecksByProductKey', to.params.productKey)
    next()
  }

  async beforeRouteEnter (to: Route, from: Route, next: Function) {
    await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    await store.dispatch('loadGvdsByProductKey', to.params.productKey)
    await store.dispatch('loadSlideDecksByProductKey', to.params.productKey)
    next()
  }

  async manageModalClosed () {
    await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
  }

  async mounted () {
    EventBus.$on('market-selected', async () => {
      if (this.$route.name !== 'product-admin') {
        return
      }
      await this.$nextTick()
      if (!this.product || !this.product.key) {
        return
      }
      await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
      await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
      await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    })
  }

  async openManageProductModal () {
    this.$bvModal.show('manage-product-modal')
  }

  async addProductMarket (newMarketKey: string) {
    const _newProductMarket = {
      productKey: this.product.key,
      marketKey: newMarketKey
    }
    await this.$store.dispatch('addNewProductMarket', _newProductMarket)
    await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
    // trigger market-selected to update main menu
    EventBus.$emit('market-selected')
  }

  async deleteProductMarket (marketKey: string) {
    const _productMarketToDelete = {
      productKey: this.product.key,
      marketKey: marketKey
    }
    await this.$store.dispatch('deleteProductMarket', _productMarketToDelete)
    await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
    // trigger market-selected to update main menu
    EventBus.$emit('market-selected')
  }

  // gvd methods

  async openManageGvdModal (key: string) {
    this.activeGvd = await this.$store.getters.getGvdByKey(key)
    this.$nextTick(() => {
      this.$bvModal.show('manage-gvd-modal')
    })
  }

  async gvdUpdated (gvdKey: string) {
    await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    if (gvdKey) {
      this.activeGvd = this.$store.getters.getGvdByKey(gvdKey)
    } else {
      this.$bvModal.hide('manage-gvd-modal')
    }
  }

  async addGvdSection ({ sectionTitle, orderIdx }: any) {
    if (!this.activeGvd) {
      return
    }
    const _newSection = {
      gvd: {
        key: (this.activeGvd as Gvd).key
      },
      title: sectionTitle,
      orderIdx,
      gvdPages: []
    }
    await this.$store.dispatch('addNewGvdSection', _newSection)
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async deleteGvdSection (sectionKey: string) {
    if (!this.activeGvd) {
      return
    }
    await this.$store.dispatch('deleteGvdSection', sectionKey)
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async addGvdPage ({ sectionKey, pageTitle, orderIdx, count }: any) {
    if (!this.activeGvd) {
      return
    }
    for (let i = 0; i < count; i++) {
      const _newPage = {
        gvdSection: {
          key: sectionKey
        },
        title: pageTitle,
        orderIdx: (parseInt(orderIdx) + i + 1)
      }
      await this.$store.dispatch('addNewGvdPage', _newPage)
    }
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async deleteGvdPage (pageKey: string) {
    if (!this.activeGvd) {
      return
    }
    await this.$store.dispatch('deleteGvdPage', pageKey)
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async gvdSectionReordered (section: GvdSection) {
    if (!this.activeGvd) {
      return
    }
    await this.$store.dispatch('reorderGvdSection', section)
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async gvdPageReordered (page: GvdPage) {
    if (!this.activeGvd) {
      return
    }
    await this.$store.dispatch('reorderGvdPage', page)
    this.activeGvd.hasChanges = true
    await this.$store.dispatch('updateGvd', this.activeGvd)
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async deleteGvdMarket (marketKey: string) {
    if (!this.activeGvd) {
      return
    }
    const _gvdMarketToDelete = {
      gvdKey: this.activeGvd.key,
      marketKey: marketKey
    }
    await this.$store.dispatch('deleteGvdMarket', _gvdMarketToDelete)
    // update done in api, so no need for separate request here
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async addGvdMarket (newMarketKey: string) {
    if (!this.activeGvd) {
      return
    }
    const _newGvdMarket = {
      gvdKey: this.activeGvd.key,
      marketKey: newMarketKey
    }
    await this.$store.dispatch('addNewGvdMarket', _newGvdMarket)
    // update done in api, so no need for separate request here
    await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
    this.activeGvd = await this.$store.getters.getGvdByKey((this.activeGvd as Gvd).key)
  }

  async addNewGvd () {
    this.$bvModal.msgBoxConfirm('Create new GVD?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const gvdToSave = {
        title: 'New GVD',
        product: { key: this.product.key },
        isDraft: true
      }
      const newGvd: Gvd = await this.$store.dispatch('addGvd', gvdToSave)
      await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
      await this.$store.dispatch('loadGvdsByProductKey', this.product.key)
      this.activeGvd = await this.$store.getters.getGvdByKey(newGvd.key)
      this.$bvModal.show('manage-gvd-modal')
    })
  }
  // slide deck methods

  async openManageSlideDeckModal (key: string) {
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey(key)
    this.$nextTick(() => {
      this.$bvModal.show('manage-slide-deck-modal')
    })
  }

  async slideDeckUpdated (slideDeckKey: string) {
    await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: store.getters.getActiveProduct.key, marketKey: store.getters.getSelectedMarket })
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    if (slideDeckKey) {
      this.activeSlideDeck = this.$store.getters.getSlideDeckByKey(slideDeckKey)
    } else {
      this.$bvModal.hide('manage-slide-deck-modal')
    }
  }

  async addSlideDeckSection ({ sectionTitle, orderIdx }: any) {
    if (!this.activeSlideDeck) {
      return
    }
    const _newSection = {
      slideDeck: {
        key: (this.activeSlideDeck as SlideDeck).key
      },
      title: sectionTitle,
      orderIdx,
      slideDeckPages: []
    }
    await this.$store.dispatch('addNewSlideDeckSection', _newSection)
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async deleteSlideDeckSection (sectionKey: string) {
    if (!this.activeSlideDeck) {
      return
    }
    await this.$store.dispatch('deleteSlideDeckSection', sectionKey)
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async addSlideDeckPage ({ sectionKey, pageTitle, orderIdx, count }: any) {
    if (!this.activeSlideDeck) {
      return
    }
    for (let i = 0; i < count; i++) {
      const _newPage = {
        slideDeckSection: {
          key: sectionKey
        },
        title: pageTitle,
        orderIdx: (parseInt(orderIdx) + i + 1)
      }
      await this.$store.dispatch('addNewSlideDeckPage', _newPage)
    }
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async deleteSlideDeckPage (pageKey: string) {
    if (!this.activeSlideDeck) {
      return
    }
    await this.$store.dispatch('deleteSlideDeckPage', pageKey)
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async slideDeckSectionReordered (section: SlideDeckSection) {
    if (!this.activeSlideDeck) {
      return
    }
    await this.$store.dispatch('reorderSlideDeckSection', section)
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async slideDeckPageReordered (page: SlideDeckPage) {
    if (!this.activeSlideDeck) {
      return
    }
    await this.$store.dispatch('reorderSlideDeckPage', page)
    this.activeSlideDeck.hasChanges = true
    await this.$store.dispatch('updateSlideDeck', this.activeSlideDeck)
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
  }

  async deleteSlideDeckMarket (marketKey: string) {
    if (!this.activeSlideDeck) {
      return
    }
    const _slideDeckMarketToDelete = {
      slideDeckKey: this.activeSlideDeck.key,
      marketKey: marketKey
    }
    await this.$store.dispatch('deleteSlideDeckMarket', _slideDeckMarketToDelete)
    // update done in api, so no need for separate request here
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
    // trigger market-selected to update main menu
    EventBus.$emit('market-selected')
  }

  async addSlideDeckMarket (newMarketKey: string) {
    if (!this.activeSlideDeck) {
      return
    }
    const _newSlideDeckMarket = {
      slideDeckKey: this.activeSlideDeck.key,
      marketKey: newMarketKey
    }
    await this.$store.dispatch('addNewSlideDeckMarket', _newSlideDeckMarket)
    // update done in api, so no need for separate request here
    await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
    this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey((this.activeSlideDeck as SlideDeck).key)
    // trigger market-selected to update main menu
    EventBus.$emit('market-selected')
  }

  async addNewSlideDeck () {
    this.$bvModal.msgBoxConfirm('Create new Slide deck?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const slideDeckToSave = {
        title: 'New slide deck',
        product: { key: this.product.key },
        isDraft: true
      }
      const newSlideDeck: SlideDeck = await this.$store.dispatch('addSlideDeck', slideDeckToSave)
      await this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
      await this.$store.dispatch('loadSlideDecksByProductKey', this.product.key)
      this.activeSlideDeck = await this.$store.getters.getSlideDeckByKey(newSlideDeck.key)
      this.$bvModal.show('manage-slide-deck-modal')
    })
  }
}
