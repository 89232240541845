import { render, staticRenderFns } from "./EditGvdSection.modal.vue?vue&type=template&id=3c53243a&"
import script from "./EditGvdSection.modal.vue?vue&type=script&lang=ts&"
export * from "./EditGvdSection.modal.vue?vue&type=script&lang=ts&"
import style0 from "./EditGvdSection.modal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports