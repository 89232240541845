

































































































































































































import axios from 'axios'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { SlideDeck, SlideDeckSection, SlideDeckPage } from '@/store/modules/slideDeck'
import { EventBus } from '@/utils/event-bus'
import SlideDeckPublishModal from './summary-slides/@components/SlideDeckPublish.modal.vue'
import EditSlideDeckSectionModal from './EditSlideDeckSection.modal.vue'
import EditSlideDeckPageModal from './EditSlideDeckPage.modal.vue'
import { saveAs } from 'file-saver'
import { hasPermissions } from '@/utils/auth'

@Component({
  components: {
    EditSlideDeckSectionModal,
    EditSlideDeckPageModal,
    SlideDeckPublishModal
  }
})
export default class ManageSlideDeck extends Vue {
  @Prop() slideDeck!: SlideDeck;
  file: any = null;
  pptxFile: any = null;
  newSectionTitle: string = '';
  newMarketKey: string = '';
  pageTitle: any = {}
  sectionSortingOptions: object = {
    dropzoneSelector: '.section-list',
    draggableSelector: '.section-list-item',
    // handlerSelector: '.page-order-handle',
    handlerSelector: null,
    reactivityEnabled: true,
    multipleDropzonesItemsDraggingEnabled: true,
    showDropzoneAreas: true
  }

  pageSortingOptions: object = {
    dropzoneSelector: '.page-list',
    draggableSelector: '.page-list-item',
    // handlerSelector: '.page-order-handle',
    handlerSelector: null,
    reactivityEnabled: true,
    multipleDropzonesItemsDraggingEnabled: true,
    showDropzoneAreas: true
  }

  activeSlideDeckSection: SlideDeckSection | null = null
  activeSlideDeckPage: SlideDeckPage | null = null

  dismissSecs: number = 5;
  dismissCountDown: number = 0;
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get product () {
    return this.$store.getters.getActiveProduct
  }

  get regions () {
    return this.$store.getters.getRegions
  }

  get slideDeckSections () {
    const _slideDeckSections = this.slideDeck.slideDeckSections.sort((a: SlideDeckSection, b: SlideDeckSection) => { return a.orderIdx - b.orderIdx })
    let _pageNo = 1
    _slideDeckSections.forEach((slideDeckSection: SlideDeckSection) => {
      slideDeckSection.slideDeckPages.sort((a: SlideDeckPage, b: SlideDeckPage) => { return a.orderIdx - b.orderIdx })
      slideDeckSection.slideDeckPages.forEach((slideDeckPage: SlideDeckPage) => {
        slideDeckPage.pageNo = _pageNo++
      })
    })
    return _slideDeckSections
  }

  async downloadFile (slideDeck: SlideDeck, _format: string = 'pdf') {
    _format = _format || (hasPermissions(['slideDeck.download']) ? 'pdf' : 'pptx')
    EventBus.$emit('show-loader')
    const resp = await axios.get(`slide-decks/download${_format === 'pdf' ? '-pdf' : ''}/${slideDeck.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: _format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    saveAs(_blob, `${slideDeck.title}${_format === 'pdf' ? '.pdf' : '.pptx'}`)
    EventBus.$emit('hide-loader')
  }

  openPublishModal () {
    this.$bvModal.show('slide-deck-publish-modal')
  }

  publish (updateDetails: any) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to publish?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$bvModal.hide('slide-deck-publish-modal')
      EventBus.$emit('show-loader')
      this.$store.dispatch('publishSlideDeck', { slideDeck: this.slideDeck, updateDetails: updateDetails }).then((newDraft: SlideDeck) => {
        EventBus.$emit('hide-loader')
        this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: this.$store.getters.getSelectedMarket }).then(() => {
          EventBus.$emit('market-selected')
          this.slideDeckUpdated()
          // this.$bvModal.msgBoxOk('Publish complete', {
          //   size: 'sm',
          //   buttonSize: 'sm',
          //   okTitle: 'OK',
          //   footerClass: 'p-2',
          //   hideHeaderClose: false,
          //   centered: true
          // })
          // this.$router.push({ name: 'product-admin', params: { productKey: this.$route.params.productKey } })
        })
        // this.$store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
        //   // handler for if api fails entirely:
        //   if (accessToken === null) {
        //     // console.log('accessToken === null')
        //     this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        //     return
        //   }
        //   // refetch now we have permissions
        //   this.$store.dispatch('loadProductAdmin', { productKey: this.product.key, marketKey: this.$store.getters.getSelectedMarket }).then(() => {
        //     EventBus.$emit('market-selected')
        //     this.slideDeckUpdated()
        //     // this.$router.push({ name: 'product-admin', params: { productKey: this.$route.params.productKey } })
        //   })
        //   // on successfully getting token:
        // }).catch(() => {
        //   console.log('fetchNewAccessToken fail')
        //   this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        // })
      })
    })
  }

  pagesReordered (event: any) {
    const _sectionKey: string = (event.detail.items[0] as HTMLElement).getAttribute('data-section-key') || ''
    if (!_sectionKey.length) {
      this.$bvModal.msgBoxOk('Failed to reorder.', {
        title: 'Error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        centered: true
      })
      return
    }
    const _pageKey: string = event.detail.ids[0]
    const _newIdx: number = event.detail.index

    const _section = this.slideDeck.slideDeckSections.find((s: SlideDeckSection) => {
      return s.key === _sectionKey
    })
    if (_section) {
      const _pageToReorder = _section.slideDeckPages.find((p: SlideDeckPage) => {
        return p.key === _pageKey
      })
      if (_pageToReorder) {
        _pageToReorder.orderIdx = _newIdx
        _pageToReorder.slideDeckSection = {
          key: _sectionKey
        } as SlideDeckSection
        this.$emit('page-reordered', _pageToReorder)
        return
      }
    }

    this.$bvModal.msgBoxOk('Failed to reorder.', {
      title: 'Error',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'error',
      centered: true
    })
  }

  sectionsReordered (event: any) {
    const _sectionKey: string = event.detail.ids[0]
    const _newIdx: number = event.detail.index

    const _sectionToReorder = this.slideDeck.slideDeckSections.find((s: SlideDeckSection) => {
      return s.key === _sectionKey
    })
    if (_sectionToReorder) {
      _sectionToReorder.orderIdx = _newIdx
      _sectionToReorder.slideDeck = {
        key: this.slideDeck.key
      } as SlideDeck
      this.$emit('section-reordered', _sectionToReorder)
      return
    }

    this.$bvModal.msgBoxOk('Failed to reorder.', {
      title: 'Error',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'error',
      centered: true
    })
  }

  addSlideDeckSection () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to add a new Slide deck Section?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-slide-deck-section', { sectionTitle: this.newSectionTitle, orderIdx: this.slideDeck.slideDeckSections.length })
      })
  }

  deleteSlideDeckSection (sectionKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this Slide deck Section?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-slide-deck-section', sectionKey)
      })
  }

  addSlideDeckPage (sectionKey: string, order: number) {
    let _noPagesToAdd = 1
    if (this.pageTitle[`${sectionKey}_count`] && this.pageTitle[`${sectionKey}_count`] > 0) {
      _noPagesToAdd = this.pageTitle[`${sectionKey}_count`]
    }
    this.$bvModal
      .msgBoxConfirm(`Are you sure you wish to add ${_noPagesToAdd} new Slide deck Page${_noPagesToAdd > 1 ? 's' : ''}?`, {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-slide-deck-page', { sectionKey: sectionKey, pageTitle: this.pageTitle[sectionKey] || '', orderIdx: order, count: _noPagesToAdd })
      })
  }

  deletePage (pageKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this Slide deck Page?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-slide-deck-page', pageKey)
      })
  }

  async openEditSectionModal (section: SlideDeckSection) {
    this.activeSlideDeckSection = section
    await this.$nextTick()
    await this.$nextTick() // TODO: look into why I need to do this twice!
    this.$bvModal.show('edit-slide-deck-section-modal')
  }

  async openEditPageModal (page: SlideDeckPage) {
    this.activeSlideDeckPage = page
    await this.$nextTick()
    await this.$nextTick() // TODO: look into why I need to do this twice!
    this.$bvModal.show('edit-slide-deck-page-modal')
  }

  editPageModalClosed () {
    this.slideDeckUpdated()
  }

  closeModal () {
    this.$bvModal.hide('manage-slide-deck-modal')
  }

  saveChanges () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to save changes?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.slideDeck.hasChanges = true
        this.$store.dispatch('saveSlideDeck', this.slideDeck).then(() => {
          // eslint-disable-next-line no-useless-escape
          if (this.file && this.file.name.split(/\.(?=[^\.]+$)/)[1] === 'pdf') {
            const formData = new FormData()
            formData.append(this.slideDeck.key, this.file)

            this.$store
              .dispatch('saveSlideDeckFile', {
                key: this.slideDeck.key,
                formData: formData
              }).then(() => {
                this.slideDeck.pdfFilename = this.file.name
                this.$store.dispatch('saveSlideDeck', this.slideDeck)
              })
          }
          // eslint-disable-next-line no-useless-escape
          if (this.pptxFile && this.pptxFile.name.split(/\.(?=[^\.]+$)/)[1] === 'pptx') {
            const formData = new FormData()
            formData.append(this.slideDeck.key, this.pptxFile)

            this.$store
              .dispatch('saveSlideDeckFile', {
                key: this.slideDeck.key,
                formData: formData
              }).then(() => {
                this.slideDeck.pptxFilename = this.pptxFile.name
                this.$store.dispatch('saveSlideDeck', this.slideDeck)
              })
          }
          this.slideDeckUpdated()
        })
      })
  }

  deleteMarket (marketKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-slide-deck-market', marketKey)
      })
  }

  addMarket () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to add this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-slide-deck-market', this.newMarketKey)
        this.newMarketKey = ''
      })
  }

  @Emit('slideDeckUpdated')
  slideDeckUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide ? '' : this.slideDeck.key
  }
}
