
































































































































































































import axios from 'axios'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Gvd, GvdSection, GvdPage } from '@/store/modules/gvd'
import { EventBus } from '@/utils/event-bus'
import GvdPublishModal from './gvds/@components/GvdPublish.modal.vue'
import EditGvdSectionModal from './EditGvdSection.modal.vue'
import EditGvdPageModal from './EditGvdPage.modal.vue'
import { saveAs } from 'file-saver'
import { hasPermissions } from '@/utils/auth'

@Component({
  components: {
    EditGvdSectionModal,
    EditGvdPageModal,
    GvdPublishModal
  }
})
export default class ManageGvd extends Vue {
  @Prop() gvd!: Gvd;
  file: any = null;
  docxFile: any = null;
  newSectionTitle: string = '';
  newMarketKey: string = ''
  pageTitle: any = {}
  sectionSortingOptions: object = {
    dropzoneSelector: '.section-list',
    draggableSelector: '.section-list-item',
    // handlerSelector: '.page-order-handle',
    handlerSelector: null,
    reactivityEnabled: true,
    multipleDropzonesItemsDraggingEnabled: true,
    showDropzoneAreas: true
  }

  pageSortingOptions: object = {
    dropzoneSelector: '.page-list',
    draggableSelector: '.page-list-item',
    // handlerSelector: '.page-order-handle',
    handlerSelector: null,
    reactivityEnabled: true,
    multipleDropzonesItemsDraggingEnabled: true,
    showDropzoneAreas: true
  }

  activeGvdPage: GvdPage | null = null
  activeGvdSection: GvdSection | null = null

  dismissSecs: number = 5;
  dismissCountDown: number = 0;
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get product () {
    return this.$store.getters.getActiveProduct
  }

  get regions () {
    return this.$store.getters.getRegions
  }

  get gvdSections () {
    const _gvdSections = this.gvd.gvdSections.sort((a: GvdSection, b: GvdSection) => { return a.orderIdx - b.orderIdx })
    let _pageNo = 1
    _gvdSections.forEach((gvdSection: GvdSection) => {
      gvdSection.gvdPages.sort((a: GvdPage, b: GvdPage) => { return a.orderIdx - b.orderIdx })
      gvdSection.gvdPages.forEach((gvdPage: GvdPage) => {
        gvdPage.pageNo = _pageNo++
      })
    })
    return _gvdSections
  }

  async downloadFile (gvd: Gvd, _format: string = 'pdf') {
    _format = _format || (hasPermissions(['gvd.download.pdf']) ? 'pdf' : 'docx')
    EventBus.$emit('show-loader')
    const resp = await axios.get(`gvds/download-${_format}/${gvd.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: _format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    saveAs(_blob, `${gvd.title}${_format === 'pdf' ? '.pdf' : '.docx'}`)
    EventBus.$emit('hide-loader')
  }

  openPublishModal () {
    this.$bvModal.show('gvd-publish-modal')
  }

  publish (updateDetails: any) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to publish?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$bvModal.hide('gvd-publish-modal')
      EventBus.$emit('show-loader')
      this.$store.dispatch('publishGvd', { gvd: this.gvd, updateDetails: updateDetails }).then((newDraft: Gvd) => {
        EventBus.$emit('hide-loader')
        this.$store.dispatch('loadProductAdminIncDisabled', { productKey: this.product.key, marketKey: this.$store.getters.getSelectedMarket }).then(() => {
          EventBus.$emit('market-selected')
          this.gvdUpdated()
          // this.$bvModal.msgBoxOk('Publish complete', {
          //   size: 'sm',
          //   buttonSize: 'sm',
          //   okTitle: 'OK',
          //   footerClass: 'p-2',
          //   hideHeaderClose: false,
          //   centered: true
          // })
          // this.$router.push({ name: 'product-admin', params: { productKey: this.$route.params.productKey } })
        })
        // this.$store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
        //   // handler for if api fails entirely:
        //   if (accessToken === null) {
        //     // console.log('accessToken === null')
        //     this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        //     return
        //   }
        //   // refetch now we have permissions
        //   this.$store.dispatch('loadProductAdmin', { productKey: this.product.key, marketKey: this.$store.getters.getSelectedMarket }).then(() => {
        //     EventBus.$emit('market-selected')
        //     this.gvdUpdated()
        //     // this.$router.push({ name: 'product-admin', params: { productKey: this.$route.params.productKey } })
        //   })
        //   // on successfully getting token:
        // }).catch(() => {
        //   console.log('fetchNewAccessToken fail')
        //   this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        // })
      })
    })
  }

  pagesReordered (event: any) {
    const _sectionKey: string = (event.detail.items[0] as HTMLElement).getAttribute('data-section-key') || ''
    if (!_sectionKey.length) {
      this.$bvModal.msgBoxOk('Failed to reorder.', {
        title: 'Error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        centered: true
      })
      return
    }
    const _pageKey: string = event.detail.ids[0]
    const _newIdx: number = event.detail.index

    const _section = this.gvd.gvdSections.find((s: GvdSection) => {
      return s.key === _sectionKey
    })
    if (_section) {
      const _pageToReorder = _section.gvdPages.find((p: GvdPage) => {
        return p.key === _pageKey
      })
      if (_pageToReorder) {
        _pageToReorder.orderIdx = _newIdx
        _pageToReorder.gvdSection = {
          key: _sectionKey
        } as GvdSection
        this.$emit('page-reordered', _pageToReorder)
        return
      }
    }

    this.$bvModal.msgBoxOk('Failed to reorder.', {
      title: 'Error',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'error',
      centered: true
    })
  }

  sectionsReordered (event: any) {
    const _sectionKey: string = event.detail.ids[0]
    const _newIdx: number = event.detail.index

    const _sectionToReorder = this.gvd.gvdSections.find((s: GvdSection) => {
      return s.key === _sectionKey
    })
    if (_sectionToReorder) {
      _sectionToReorder.orderIdx = _newIdx
      _sectionToReorder.gvd = {
        key: this.gvd.key
      } as Gvd
      this.$emit('section-reordered', _sectionToReorder)
      return
    }

    this.$bvModal.msgBoxOk('Failed to reorder.', {
      title: 'Error',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'error',
      centered: true
    })
  }

  addGvdSection () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to add a new GVD Section?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-gvd-section', { sectionTitle: this.newSectionTitle, orderIdx: this.gvd.gvdSections.length })
      })
  }

  deleteGvdSection (sectionKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this GVD Section?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-gvd-section', sectionKey)
      })
  }

  addGvdPage (sectionKey: string, order: number) {
    let _noPagesToAdd = 1
    if (this.pageTitle[`${sectionKey}_count`] && this.pageTitle[`${sectionKey}_count`] > 0) {
      _noPagesToAdd = this.pageTitle[`${sectionKey}_count`]
    }
    this.$bvModal
      .msgBoxConfirm(`Are you sure you wish to add ${_noPagesToAdd} new GVD Page${_noPagesToAdd > 1 ? 's' : ''}?`, {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-gvd-page', { sectionKey: sectionKey, pageTitle: this.pageTitle[sectionKey] || '', orderIdx: order, count: _noPagesToAdd })
      })
  }

  deletePage (pageKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this GVD Page?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-gvd-page', pageKey)
      })
  }

  async openEditSectionModal (section: GvdSection) {
    this.activeGvdSection = section
    await this.$nextTick()
    await this.$nextTick() // TODO: look into why I need to do this twice!
    this.$bvModal.show('edit-gvd-section-modal')
  }

  async openEditPageModal (page: GvdPage) {
    this.activeGvdPage = page
    await this.$nextTick()
    await this.$nextTick() // TODO: look into why I need to do this twice!
    this.$bvModal.show('edit-gvd-page-modal')
  }

  editPageModalClosed () {
    this.gvdUpdated()
  }

  closeModal () {
    this.$bvModal.hide('manage-gvd-modal')
  }

  saveChanges () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to save changes?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.gvd.hasChanges = true
        this.$store.dispatch('saveGvd', this.gvd).then(() => {
          // eslint-disable-next-line no-useless-escape
          if (this.file && this.file.name.split(/\.(?=[^\.]+$)/)[1] === 'pdf') {
            const formData = new FormData()
            formData.append(this.gvd.key, this.file)

            this.$store
              .dispatch('saveGvdFile', {
                key: this.gvd.key,
                formData: formData
              }).then(() => {
                this.gvd.pdfFilename = this.file.name
                this.$store.dispatch('saveGvd', this.gvd)
              })
          }
          // eslint-disable-next-line no-useless-escape
          if (this.docxFile && this.docxFile.name.split(/\.(?=[^\.]+$)/)[1] === 'docx') {
            const formData = new FormData()
            formData.append(this.gvd.key, this.docxFile)

            this.$store
              .dispatch('saveGvdFile', {
                key: this.gvd.key,
                formData: formData
              }).then(() => {
                this.gvd.docxFilename = this.docxFile.name
                this.$store.dispatch('saveGvd', this.gvd)
              })
          }
          this.gvdUpdated()
        })
      })
  }

  deleteMarket (marketKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-gvd-market', marketKey)
      })
  }

  addMarket () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to add this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-gvd-market', this.newMarketKey)
        this.newMarketKey = ''
      })
  }

  @Emit('gvdUpdated')
  gvdUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide ? '' : this.gvd.key
  }
}
