














































import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class SlideDeckPublish extends Vue {
  updateDetails: any = {
    summary: '',
    description: '',
    versionStr: '',
    sendNotifications: false
  }

  @Emit('publish')
  confirmPublish () {
    return this.updateDetails
  }

  closeModal () {
    this.$bvModal.hide('slideDeck-publish-modal')
  }
}
